import React, { useState, useEffect } from "react";
import { ArrowLeft, Image, Plus, X, CircleCheckBig, Trash, ChevronsUpDown, CheckCircle, XCircle, Clock } from "lucide-react";
import {
  getBase64FromDataURL,
  readFileAsDataURL,
} from "./helper";
import { useSearchParams } from "react-router-dom";
import axios from "axios";
import { crateExpense, fetchBankDetails, uploadBillswithImage, createEmployeeExpenseReimbursement, addEmployeeExpenseBill } from "../../services/expense";
import { toast } from 'sonner'

const BASE_URL = process.env.REACT_APP_API_BASE_URL;

const months = [
  'January', 'February', 'March', 'April', 'May', 'June',
  'July', 'August', 'September', 'October', 'November', 'December'
];

const reimbursementOptions = {
  hubs: "Hubs Reimbursement",
  employees: "Employee Reimbursement"
};

const TimelineItem = ({ title, status }) => {
  const getStatusIcon = () => {
    switch (status) {
      case 'complete':
        return <CheckCircle className="w-5 h-5 text-green-500" />;
      case 'error':
        return <XCircle className="w-5 h-5 text-red-500" />;
      default:
        return <Clock className="w-5 h-5 text-yellow-500 animate-spin" />;
    }
  };

  return (
    <div className="flex items-center mb-4">
      <div className="flex-shrink-0 mr-2">
        {getStatusIcon()}
      </div>
      <div className="flex-grow">
        <p className={`text-sm ${status === 'complete' ? 'text-green-500' : status === 'error' ? 'text-red-500' : 'text-yellow-500'}`}>
          {title}
        </p>
      </div>
    </div>
  );
};

const Timeline = ({ children }) => {
  return (
    <div className="border-l-2 border-gray-700 pl-4">
      {children}
    </div>
  );
};

const Expense = () => {

  const [searchParams] = useSearchParams();
  const [reimbursementType, setReimbursementType] = useState('hubs');
  const [uploadStatus, setUploadStatus] = useState("");
  const [currentStep, setCurrentStep] = useState(1);
  const [currentEmployeeStep, setCurrentEmployeeStep] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [isUploading, setIsUploading] = useState(false);
  const [isEmployeeUploading, setIsEmployeeUploading] = useState(false);

  const [hubs, setHubs] = useState([])
  const [reviewInfo, setReviewInfo] = useState({ employee_code: '', hub_name: '', name: '' })
  const [formData, setFormData] = useState([{
    image: null,
    preview: null,
    type: '',
    remarks: '',
    amount: 0
  }]);

  const [formDataEmployee, setFormDataEmployee] = useState([{
    image: null,
    preview: null,
    type: '',
    description: '',
    amount: 0,
    bills_attached: false,
    date: null
  }]);


  const [bankInfo, setBankInfo] = useState({
    bank_name: null,
    account_number: null,
    reenter_account_number: null,
    account_holder_name: '',
    ifsc_code: null
  })
  const [formErrors, setFormErrors] = useState({});
  const [formEmployeeErrors, setFormEmployeeErrors] = useState({});
  const [reviewErrors, setReviewErrors] = useState({});
  const [isBankInfoExist, setBankInfoExist] = useState(true);
  const [timelineItems, setTimelineItems] = useState([]);

  const handleReviewInfoChange = (event) => {
    const { name, value } = event.target;
    setReviewInfo(prevInfo => ({
      ...prevInfo,
      [name]: value
    }));
  };

  const handleBankInfo = (event) => {
    const { name, value } = event.target;
    setBankInfo(prevInfo => ({
      ...prevInfo,
      [name]: value
    }));
  }

  const handleFileChange = (index, e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setFormData(prevData => {
          const newData = [...prevData];
          newData[index] = {
            ...newData[index],
            image: file, preview: reader.result
          };
          return newData;
        });
      };
      reader.readAsDataURL(file);
    }
  };

  const handleEmployeeFileChange = (index, e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setFormDataEmployee(prevData => {
          const newData = [...prevData];
          newData[index] = {
            ...newData[index],
            image: file, preview: reader.result, bills_attached: true
          };
          return newData;
        });
      };
      reader.readAsDataURL(file);
    }
  }

  const handleEmployeeInputChange = (index, e) => {
    const { name, value } = e.target;
    setFormDataEmployee(prevData => {
      const newData = [...prevData];
      newData[index] = { ...newData[index], [name]: value };
      return newData;
    });
    // Clear error when user starts typing
    if (formErrors[name]) {
      setFormEmployeeErrors(prevErrors => ({ ...prevErrors, [name]: '' }));
    }
  }

  const removeEmployeeSection = (index) => {
    setFormDataEmployee(prevData => prevData.filter((_, i) => i !== index));
    // Also remove any errors associated with this form
    setFormEmployeeErrors(prevErrors => {
      const newErrors = { ...prevErrors };
      delete newErrors[`image-${index}`];
      delete newErrors[`type-${index}`];
      delete newErrors[`remarks-${index}`];
      delete newErrors[`amount-${index}`];
      return newErrors;
    });
  };

  const handleInputChange = (index, e) => {
    const { name, value } = e.target;
    setFormData(prevData => {
      const newData = [...prevData];
      newData[index] = { ...newData[index], [name]: value };
      return newData;
    });
    // Clear error when user starts typing
    if (formErrors[name]) {
      setFormErrors(prevErrors => ({ ...prevErrors, [name]: '' }));
    }
  };

  const removeImage = (index) => {
    setFormData(prevData => {
      const newData = [...prevData];
      newData[index] = {
        ...newData[index],
        image: null,
        preview: null
      };
      return newData;
    });
  };

  const removeEmployeeImage = (index) => {
    setFormDataEmployee(prevData => {
      const newData = [...prevData];
      newData[index] = {
        ...newData[index],
        image: null,
        preview: null
      };
      return newData;
    });
  }

  const addSection = () => {
    setFormData(prevData => [...prevData, {
      image: null,
      preview: null,
      type: '',
      remarks: '',
      amount: ''
    }]);
  };

  const employeeAddSection = () => {
    setFormDataEmployee(prevData => [...prevData, {
      image: null,
      preview: null,
      type: '',
      description: '',
      amount: 0,
      bills_attached: false,
      date: null
    }])
  }

  const removeSection = (index) => {
    setFormData(prevData => prevData.filter((_, i) => i !== index));
    // Also remove any errors associated with this form
    setFormErrors(prevErrors => {
      const newErrors = { ...prevErrors };
      delete newErrors[`image-${index}`];
      delete newErrors[`type-${index}`];
      delete newErrors[`remarks-${index}`];
      delete newErrors[`amount-${index}`];
      return newErrors;
    });
  };

  const onReviewInfoSubmit = async () => {
    const errors = {};
    if (!reviewInfo.employee_code) errors[`employee_code`] = 'Employee code is required';
    if (!reviewInfo.hub_name) errors[`hub_name`] = 'Hub is required';
    if (Object.keys(errors).length > 0) {
      setReviewErrors(errors);
      return;
    }
    setCurrentStep(2);
  }

  const onReviewEmployeeInfoSubmit = async () => {
    const errors = {};
    if (!reviewInfo.employee_code) errors[`employee_code`] = 'Employee code is required';
    if (Object.keys(errors).length > 0) {
      setReviewErrors(errors);
      return;
    }
    setCurrentEmployeeStep(2)
  }

  const onBankInfoSubmit = async () => {
    setCurrentStep(4);
  }

  const onEmployeeBankInfoSubmit = async () => {
    setCurrentEmployeeStep(4);
  }

  const handleUpload = async () => {
    // Validate all sections
    const errors = {};
    formData.forEach((data, index) => {
      if (!data.image) errors[`image-${index}`] = 'Image is required';
      if (!data.type) errors[`type-${index}`] = 'Bill type is required';
      if (!data.remarks) errors[`remarks-${index}`] = 'Date is required';
      if (!data.amount) errors[`amount-${index}`] = 'Amount is required';
      if (parseFloat(data.amount) <= 0) errors[`amount-${index}`] = 'Amount must be greater than zero';
    });

    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      setUploadStatus("Please fill in all required fields correctly.");
      return;
    }
    setIsUploading(true);
    setUploadStatus("Uploading...");
    setTimelineItems([]);

    try {
      // Step 1: Create Expense
      setTimelineItems(prev => [...prev, { title: 'Creating Expense', status: 'in-progress' }]);
      const response = await crateExpense({ employee_id: reviewInfo?.employee_code, hub_name: reviewInfo?.hub_name });
      const newExpenseId = response.data.id;
      setTimelineItems(prev => prev.map(item =>
        item.title === 'Creating Expense' ? { ...item, status: 'complete' } : item
      ));

      // Step 2: Upload Bills
      setTimelineItems(prev => [...prev, { title: 'Uploading Bills', status: 'in-progress' }]);
      await Promise.all(
        formData.map(async ({ image, preview, ...data }, index) => {
          if (parseFloat(data.amount) <= 0) {
            throw new Error('Amount must be greater than zero');
          }

          try {
            const dataURL = await readFileAsDataURL(image);
            const base64Image = getBase64FromDataURL(dataURL);
            const payload = { ...data, image: base64Image };
            const response = await uploadBillswithImage(newExpenseId, payload, reviewInfo.employee_code);
            console.log(response, "-- upload response --");
          } catch (error) {
            toast.error(`Failed to upload bill ${index + 1}: ${error.message}`);
            throw error; // Re-throw to mark the overall process as failed
          }
        })
      );
      setTimelineItems(prev => prev.map(item =>
        item.title === 'Uploading Bills' ? { ...item, status: 'complete' } : item
      ));

      // Step 3: Fetch Bank Details
      setTimelineItems(prev => [...prev, { title: 'Fetching Bank Details', status: 'in-progress' }]);
      const fetchedBankInfo = await fetchBankDetails(reviewInfo.employee_code);
      if (!fetchedBankInfo) {
        setBankInfoExist(false);
      }
      const initialInfo = {
        bank_name: fetchedBankInfo?.bank_name || null,
        account_number: fetchedBankInfo?.account_number || null,
        account_holder_name: fetchedBankInfo?.account_holder_name || null,
        ifsc_code: fetchedBankInfo?.ifsc_code || null
      };
      setBankInfo(initialInfo);
      setTimelineItems(prev => prev.map(item =>
        item.title === 'Fetching Bank Details' ? { ...item, status: 'complete' } : item
      ));

      setUploadStatus("Upload successful! Analysis complete.");
      setCurrentStep(3);
    } catch (error) {
      setUploadStatus("Upload failed. Please try again.");
      console.error("Error uploading images:", error);
      setTimelineItems(prev => prev.map(item =>
        item.status === 'in-progress' ? { ...item, status: 'error' } : item
      ));
    } finally {
      setIsUploading(false);
    }
  };


  const onEmployeeReimbursementSubmit = async () => {
    const errors = {};
    formDataEmployee.forEach((data, index) => {
      if (!data.type) errors[`type-${index}`] = 'Bill type is required';
      if (!data.amount) errors[`amount-${index}`] = 'Amount is required';
      if (parseFloat(data.amount) <= 0) errors[`amount-${index}`] = 'Amount must be greater than zero';
      if (!data.date) errors[`date-${index}`] = 'Date is required';
    });

    if (Object.keys(errors).length > 0) {
      setFormEmployeeErrors(errors);
      return;
    }
    setIsEmployeeUploading(true);
    setTimelineItems([]);

    try {
      // Step 1: Create Employee Expense Reimbursement
      setTimelineItems(prev => [...prev, { title: 'Creating Expense Reimbursement', status: 'in-progress' }]);
      const response = await createEmployeeExpenseReimbursement({ employee_id: reviewInfo?.employee_code });
      const newEmployeeExpenseId = response.data.id;
      setTimelineItems(prev => prev.map(item =>
        item.title === 'Creating Expense Reimbursement' ? { ...item, status: 'complete' } : item
      ));

      // Step 2: Upload Bills
      setTimelineItems(prev => [...prev, { title: 'Uploading Bills', status: 'in-progress' }]);
      await Promise.all(
        formDataEmployee.map(async ({ image, preview, ...data }, index) => {
          let payload = { ...data };

          if (parseFloat(data.amount) <= 0) {
            throw new Error('Amount must be greater than zero');
          }

          if (image) {
            const dataURL = await readFileAsDataURL(image);
            const base64Image = getBase64FromDataURL(dataURL);
            payload.image = base64Image;
          }

          try {
            const response = await addEmployeeExpenseBill(payload, newEmployeeExpenseId);
            return response;
          } catch (error) {
            toast.error(`Failed to upload bill ${index + 1}: ${error.message}`);
            throw error;
          }
        })
      );
      setTimelineItems(prev => prev.map(item =>
        item.title === 'Uploading Bills' ? { ...item, status: 'complete' } : item
      ));

      // Step 3: Fetch Bank Details
      setTimelineItems(prev => [...prev, { title: 'Fetching Bank Details', status: 'in-progress' }]);
      const fetchedBankInfo = await fetchBankDetails(reviewInfo.employee_code);

      if (!fetchedBankInfo) {
        setBankInfoExist(false);
      }

      const initialInfo = {
        bank_name: fetchedBankInfo?.bank_name || null,
        account_number: fetchedBankInfo?.account_number || null,
        account_holder_name: fetchedBankInfo?.account_holder_name || null,
        ifsc_code: fetchedBankInfo?.ifsc_code || null
      };

      setBankInfo(initialInfo);
      setTimelineItems(prev => prev.map(item =>
        item.title === 'Fetching Bank Details' ? { ...item, status: 'complete' } : item
      ));

      setCurrentEmployeeStep(3);
    } catch (error) {
      toast.error(error.message);
      setTimelineItems(prev => prev.map(item =>
        item.status === 'in-progress' ? { ...item, status: 'error' } : item
      ));
    } finally {
      setIsEmployeeUploading(false);
    }
  }

  useEffect(() => {
    const fetchUserData = async () => {
      const id = searchParams.get("id");
      if (id) {
        try {
          const response = await axios.get(
            `${BASE_URL}/employee/${id}`,
            {
              headers: {
                "ngrok-skip-browser-warning": "true",
              },
            }
          );
          const userData = response.data.data;

          setReviewInfo((prevState) => ({
            ...prevState,
            employee_code: userData?.id || "",
            name: userData?.name
          }));
          let designation = userData?.designation;
          let hubRole = ""

          if (!designation || designation === 'Hub Incharge') hubRole = 'hubsInCharge';
          else if (designation === 'Level 1') hubRole = "hubsLevel1";
          else hubRole = "hubsLevel2"

          // let hubIds = userData[hubRole]
          console.log(userData[hubRole], "--- user data ---")
          setHubs(userData[hubRole]);
        } catch (err) {
          toast.error('Failed to fetch Employee data. Please try again.')
          console.error("Error fetching user data:", err);
        }
      }
      setIsLoading(false);
    };

    fetchUserData();
  }, [searchParams]);


  const renderReviewInformation = () => (
    <>
      <div className="pb-6">
        <label className="flex flex-col itms-center">
          Select Reimbursement Type:
          <div className="flex items-center border rounded-md bg-[#1F1F1F]">
            <select
              value={reimbursementType}
              onChange={(e) => setReimbursementType(e.target.value)}
              className="block w-full bg-[#1F1F1F] shadow-sm p-2 text-white appearance-none"
            >
              {Object.entries(reimbursementOptions).map(([value, label]) => (
                <option key={value} value={value}>
                  {label}
                </option>
              ))}
            </select>
            <div className="pointer-events-none flex items-center px-2 text-white">
              <ChevronsUpDown className="text-white w-5 h-5" />
            </div>
          </div>
        </label>
      </div>
      <h3 className="text-xl font-medium mb-4 text-gray-200 flex flex-col gap-6">
        <div className="flex w-full justify-between items-center">
          <p className="text-sm font-regular text-gray-100 opacity-65">
            Step 1 of 3
          </p>
        </div>
        Review Your Information
      </h3>
      {isLoading ? (
        <p className="text-white">Loading user data...</p>
      ) : !reviewInfo.employee_code ? (
        <div className="bg-red-100 border-l-4 border-red-500 text-red-700 p-4" role="alert">
          <p className="font-bold">Employee Information Not Found</p>
          <p>You must be part of the organization to submit a reimbursement request. Please contact your administrator for assistance.</p>
        </div>
      ) : (
        <div className="space-y-4 mb-6">
          <div>
            <label
              htmlFor="employee_code"
              className="block text-sm font-medium text-gray-300 mb-1"
            >
              Employee Code
            </label>
            <input
              type="text"
              id="employee_code"
              name="employee_code"
              value={reviewInfo.employee_code}
              onChange={handleReviewInfoChange}
              className={`block w-full bg-[#1F1F1F] border"
                } rounded-md shadow-sm p-2 text-white`}
              disabled
            />
            {reviewErrors['employee_code'] && (
              <p className="mt-1 text-sm text-red-500">{reviewErrors['employee_code']}</p>
            )}
          </div>
          <div>
            <label
              htmlFor="hub_name"
              className="block text-sm font-medium text-gray-300 mb-1"
            >
              Hub
            </label>
            {hubs && hubs.length > 0 ? (
              <select
                id="hub_name"
                name="hub_name"
                value={reviewInfo.hub_name}
                onChange={handleReviewInfoChange}
                className="block w-full bg-[#1F1F1F] border rounded-md shadow-sm p-2 text-white"
              >
                <option value="">Select a hub</option>
                {hubs.map((hub) => (
                  <option key={hub.id} value={hub.id}>
                    {`${hub.location} (${hub.state} - ${hub.zone})`}
                  </option>
                ))}
              </select>
            ) : (
              <div className="bg-yellow-100 border-l-4 border-yellow-500 text-yellow-700 p-4" role="alert">
                <p className="font-bold">No Hubs Assigned</p>
                <p>There are no hubs attached to your name. Please contact your administrator for assistance.</p>
              </div>
            )}
            {reviewErrors['hub_name'] && (
              <p className="mt-1 text-sm text-red-500">{reviewErrors['hub_name']}</p>
            )}
          </div>
        </div>
      )}
      {reviewInfo.employee_code && (
        <button
          onClick={onReviewInfoSubmit}
          disabled={!reviewInfo.employee_code || !reviewInfo.hub_name || isLoading}
          className={`w-full px-4 py-2 rounded-md transition-colors ${reviewInfo.employee_code && reviewInfo.hub_name && !isLoading
            ? 'bg-white text-black hover:bg-blue-700'
            : 'bg-white opacity-25 text-gray-600 cursor-not-allowed'
            }`}
        >
          Next
        </button>
      )}
    </>
  );

  const renderEmployeeReviewInformation = () => (
    <>
      <div className="pb-6">
        <label className="flex flex-col itms-center">
          Select Reimbursement Type:
          <div className="flex items-center border rounded-md bg-[#1F1F1F]">
            <select
              value={reimbursementType}
              onChange={(e) => setReimbursementType(e.target.value)}
              className="block w-full bg-[#1F1F1F] shadow-sm p-2 text-white appearance-none"
            >
              {Object.entries(reimbursementOptions).map(([value, label]) => (
                <option key={value} value={value}>
                  {label}
                </option>
              ))}
            </select>
            <div className="pointer-events-none flex items-center px-2 text-white">
              <ChevronsUpDown className="text-white w-5 h-5" />
            </div>
          </div>
        </label>
      </div>
      <h3 className="text-xl font-medium mb-4 text-gray-200 flex flex-col gap-6">
        <div className="flex w-full justify-between items-center">
          <p className="text-sm font-regular text-gray-100 opacity-65">
            Step 1 of 3
          </p>
        </div>
        Review Your Information
      </h3>
      {!reviewInfo.employee_code ? (
        <div className="bg-red-100 border-l-4 border-red-500 text-red-700 p-4" role="alert">
          <p className="font-bold">Employee Information Not Found</p>
          <p>You must be part of the organization to submit a reimbursement request. Please contact your administrator for assistance.</p>
        </div>
      ) : (
        <div className="space-y-4 mb-6">
          <div>
            <label
              htmlFor="employee_code"
              className="block text-sm font-medium text-gray-300 mb-1"
            >
              Employee Code
            </label>
            <input
              type="text"
              id="employee_code"
              name="employee_code"
              value={reviewInfo.employee_code}
              onChange={handleReviewInfoChange}
              className={`block w-full bg-[#1F1F1F] border"
                } rounded-md shadow-sm p-2 text-white`}
              disabled
            />
            {reviewErrors['employee_code'] && (
              <p className="mt-1 text-sm text-red-500">{reviewErrors['employee_code']}</p>
            )}
          </div>
        </div>
      )}
      {reviewInfo.employee_code && (
        <button
          onClick={onReviewEmployeeInfoSubmit}
          disabled={!reviewInfo.employee_code || isLoading}
          className={`w-full px-4 py-2 rounded-md transition-colors ${reviewInfo.employee_code && !isLoading
            ? 'bg-white text-black hover:bg-blue-700'
            : 'bg-white opacity-25 text-gray-600 cursor-not-allowed'
            }`}
        >
          Next
        </button>
      )}
    </>
  )

  const renderImageUpload = () => (
    <div className="max-w-2xl mx-auto">
      <h3 className="text-xl font-medium mb-4 text-gray-200 flex flex-col gap-6">
        <div className="flex w-full justify-between items-center">
          <p className="text-sm font-regular text-gray-100 opacity-65">
            Step 2 of 3
          </p>
          <button
            className={`
        text-[16px] font-regular flex gap-1 items-center
        ${isUploading
                ? 'text-gray-400 cursor-not-allowed'
                : 'text-gray-100 opacity-65 hover:opacity-100'
              }
      `}
            onClick={() => !isUploading && setCurrentStep(1)}
            disabled={isUploading}
          >
            <ArrowLeft size={18} />
            Back
          </button>
        </div>
        <div className="flex items-center justify-between">
          Bill Upload
        </div>
      </h3>
      <div>
        {formData.map((data, index) => (
          <div key={index} className="mb-8 p-4 border border-gray-700 rounded-lg">
            {formData.length > 1 && (
              <button
                onClick={() => removeSection(index)}
                disabled={isUploading}
                className="p-1.5 bg-white rounded-full text-black hover:bg-white transition-colors mb-6"
              >
                <Trash size={16} />
              </button>
            )}
            <div className="mb-6">
              <label htmlFor={`image-${index}`} className="block mb-2 text-sm font-medium text-gray-300">
                Choose an image
              </label>
              {data.preview ? (
                <div className="relative">
                  <img src={data.preview} alt="Preview" className="max-w-full h-auto rounded-lg shadow-md" />
                  <button
                    onClick={() => removeImage(index)}
                    className="absolute top-2 right-2 p-1 bg-red-500 rounded-full text-white hover:bg-red-600 transition-colors"
                    disabled={isUploading}
                  >
                    <X size={16} />
                  </button>
                </div>
              ) : (
                <div className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-600 border-dashed rounded-md">
                  <div className="space-y-1 text-center">
                    <Image size={26} className="mx-auto text-gray-400 mb-4" />
                    <div className="flex text-sm text-gray-400">
                      <label
                        htmlFor={`image-${index}`}
                        className={`relative cursor-pointer bg-gray-700 rounded-md font-medium text-blue-400 hover:text-blue-300 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-blue-500 ${isUploading ? 'cursor-not-allowed opacity-50' : ''}`}
                      >
                        <span className="p-2 rounded-lg">Upload a file</span>
                        <input
                          id={`image-${index}`}
                          name="image"
                          type="file"
                          accept="image/*"
                          onChange={(e) => handleFileChange(index, e)}
                          className="sr-only"
                          disabled={isUploading}
                        />
                      </label>
                      <p className="pl-1">or drag and drop</p>
                    </div>
                    <p className="text-xs text-gray-400">PNG, JPG up to 10MB</p>
                  </div>
                </div>
              )}
              {formErrors[`image-${index}`] && (
                <p className="mt-1 text-sm text-red-500">{formErrors[`image-${index}`]}</p>
              )}
            </div>
            <div className="mb-4">
              <label htmlFor={`type-${index}`} className="block text-sm font-medium text-gray-300 mb-1">
                Bill
              </label>
              <select
                id={`type-${index}`}
                name="type"
                value={data.type}
                onChange={(e) => handleInputChange(index, e)}
                className={`block w-full bg-[#1F1F1F] border ${formErrors[`type-${index}`] ? 'border-red-500' : 'border-[#373737]'
                  } rounded-md shadow-sm p-2 text-white ${isUploading ? 'opacity-50 cursor-not-allowed' : ''}`}
                disabled={isUploading}
              >
                <option value="">Select a bill</option>
                <option value="electricity">Electricity bill</option>
                <option value="water">Water bill</option>
                <option value="stationery">Stationery</option>
                <option value="internet">Internet</option>
                <option value="house_keeping">House Keeping</option>
                <option value="miscellaneous">Miscellaneous</option>
              </select>
              {formErrors[`type-${index}`] && (
                <p className="mt-1 text-sm text-red-500">{formErrors[`type-${index}`]}</p>
              )}
            </div>
            <div className="mb-4">
              <label htmlFor={`remarks-${index}`} className="block text-sm font-medium text-gray-300 mb-1">
                Select Month
              </label>
              <select
                id={`remarks-${index}`}
                name="remarks"
                value={data.remarks}
                onChange={(e) => handleInputChange(index, e)}
                className={`block w-full bg-[#1F1F1F] border ${formErrors[`remarks-${index}`] ? 'border-red-500' : 'border-[#373737]'
                  } rounded-md shadow-sm p-2 text-white ${isUploading ? 'opacity-50 cursor-not-allowed' : ''}`}
                disabled={isUploading}
              >
                <option value="">Select a month</option>
                {months.map((month) => (
                  <option key={month} value={month}>
                    {month}
                  </option>
                ))}
              </select>
              {formErrors[`remarks-${index}`] && (
                <p className="mt-1 text-sm text-red-500">{formErrors[`remarks-${index}`]}</p>
              )}
            </div>
            <div className="mb-4">
              <label htmlFor={`amount-${index}`} className="block text-sm font-medium text-gray-300 mb-1">
                Amount
              </label>
              <input
                type="number"
                id={`amount-${index}`}
                name="amount"
                value={data.amount !== 0 ? data.amount : ''}
                onChange={(e) => handleInputChange(index, e)}
                disabled={isUploading}
                className={`block w-full bg-[#1F1F1F] border ${formErrors[`amount-${index}`] ? 'border-red-500' : 'border-[#373737]'
                  } rounded-md shadow-sm p-2 text-white ${isUploading ? 'opacity-50 cursor-not-allowed' : ''}`}
              />
              {formErrors[`amount-${index}`] && (
                <p className="mt-1 text-sm text-red-500">{formErrors[`amount-${index}`]}</p>
              )}
            </div>
          </div>
        ))}
        <div className="flex flex-col space-y-4 mb-3 mt-6">
          <button
            type="button"
            className={`text-gray-900 bg-gray-100 hover:bg-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-100 font-medium rounded-lg text-sm px-2.5 py-1.5 text-center inline-flex items-center justify-center ${isUploading ? 'opacity-50 cursor-not-allowed' : ''
              }`}
            onClick={addSection}
            disabled={isUploading}
          >
            <Plus className="w-5 h-5 mr-2" />
            Add Bill
          </button>
        </div>
      </div>

      <div className="flex flex-col space-y-4 mb-3 mt-6">
        <button
          onClick={handleUpload}
          disabled={isUploading}
          className={`
            w-full px-4 py-2 rounded-md text-white font-semibold
            transition-colors
            ${isUploading
              ? 'bg-gray-400 cursor-not-allowed'
              : 'bg-blue-600 hover:bg-blue-700'
            }
          `}
          aria-live="polite"
        >
          {isUploading ? 'Uploading...' : 'Upload and Analyze'}
        </button>
      </div>

      {timelineItems.length > 0 && (
        <div className="mt-8" aria-live="polite">
          <h4 className="text-lg font-medium mb-4 text-gray-200">Progress</h4>
          <Timeline>
            {timelineItems.map((item, index) => (
              <TimelineItem key={index} title={item.title} status={item.status} />
            ))}
          </Timeline>
        </div>
      )}

      {uploadStatus && (
        <div
          className={`p-4 mt-6 rounded-md ${uploadStatus.includes("failed")
            ? "bg-red-900 text-red-200"
            : "bg-green-900 text-green-200"
            }`}
          role="alert"
          aria-live="assertive"
        >
          {uploadStatus}
        </div>
      )}
    </div>
  );

  const renderEmployeeExpenseBill = () => (
    <>
      <p className="text-xl pb-2 text-indigo-500 flex flex-col items-center justify-center">
        <span className="text-white">Welcome back</span>
        <span>{reviewInfo?.name}</span>
      </p>
      <h3 className="text-xl font-medium mb-4 text-gray-200 flex flex-col gap-6">
        <div className="flex w-full justify-between items-center">
          <p className="text-sm font-regular text-gray-100 opacity-65">
            Step 2 of 3
          </p>
          <button
            className={`
        text-[16px] font-regular flex gap-1 items-center
        ${isEmployeeUploading
                ? 'text-gray-400 cursor-not-allowed'
                : 'text-gray-100 opacity-65 hover:opacity-100'
              }
      `}
            onClick={() => !isEmployeeUploading && setCurrentEmployeeStep(1)}
            disabled={isEmployeeUploading}
          >
            <ArrowLeft size={18} />
            Back
          </button>
        </div>
        <div className="flex items-center justify-between">
          Employee Bill Upload
        </div>
      </h3>
      {formDataEmployee.map((data, index) => (
        <div key={index} className="mb-8 p-4 border border-gray-700 rounded-lg">
          {formDataEmployee.length > 1 && (
            <button
              onClick={() => removeEmployeeSection(index)}
              disabled={isEmployeeUploading}
              className="p-1.5 bg-white rounded-full text-black hover:bg-white transition-colors mb-6"
            >
              <Trash size={16} />
            </button>
          )}
          <div className="mb-6">
            <label htmlFor={`image-${index}`} className="block mb-2 text-sm font-medium text-gray-300">
              Choose an image (optional)
            </label>
            {data.preview ? (
              <div className="relative">
                <img src={data.preview} alt="Preview" className="max-w-full h-auto rounded-lg shadow-md" />
                <button
                  onClick={() => removeEmployeeImage(index)}
                  className="absolute top-2 right-2 p-1 bg-red-500 rounded-full text-white hover:bg-red-600 transition-colors"
                  disabled={isEmployeeUploading}
                >
                  <X size={16} />
                </button>
              </div>
            ) : (
              <div className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-600 border-dashed rounded-md">
                <div className="space-y-1 text-center">
                  <Image size={26} className="mx-auto text-gray-400 mb-4" />
                  <div className="flex text-sm text-gray-400">
                    <label
                      htmlFor={`image-${index}`}
                      className={`relative cursor-pointer bg-gray-700 rounded-md font-medium text-blue-400 hover:text-blue-300 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-blue-500 ${isEmployeeUploading ? 'cursor-not-allowed opacity-50' : ''}`}
                    >
                      <span className="p-2 rounded-lg">Upload a file</span>
                      <input
                        id={`image-${index}`}
                        name="image"
                        type="file"
                        accept="image/*"
                        onChange={(e) => handleEmployeeFileChange(index, e)}
                        className="sr-only"
                        disabled={isEmployeeUploading}
                      />
                    </label>
                    <p className="pl-1">or drag and drop</p>
                  </div>
                  <p className="text-xs text-gray-400">PNG, JPG up to 10MB</p>
                </div>
              </div>
            )}
            {formEmployeeErrors[`image-${index}`] && (
              <p className="mt-1 text-sm text-red-500">{formEmployeeErrors[`image-${index}`]}</p>
            )}
          </div>
          <div className="mb-4">
            <label htmlFor={`type-${index}`} className="block text-sm font-medium text-gray-300 mb-1">
              Expense Type
            </label>
            <select
              id={`type-${index}`}
              name="type"
              value={data.type}
              onChange={(e) => handleEmployeeInputChange(index, e)}
              className={`block w-full bg-[#1F1F1F] border ${formEmployeeErrors[`type-${index}`] ? 'border-red-500' : 'border-[#373737]'
                } rounded-md shadow-sm p-2 text-white ${isEmployeeUploading ? 'opacity-50 cursor-not-allowed' : ''}`}
              disabled={isEmployeeUploading}
            >
              <option value="">Select a bill</option>
              <option value="accommodation">Accommodation</option>
              <option value="travel">Travel</option>
              <option value="food">Food</option>
              <option value="miscellaneous">Miscellaneous</option>
            </select>
            {formEmployeeErrors[`type-${index}`] && (
              <p className="mt-1 text-sm text-red-500">{formEmployeeErrors[`type-${index}`]}</p>
            )}
          </div>
          <div className="mb-4">
            <label htmlFor={`description-${index}`} className="block text-sm font-medium text-gray-300 mb-1">
              Description (optional)
            </label>
            <input
              type="description"
              id={`description-${index}`}
              name="description"
              value={data.description}
              onChange={(e) => handleEmployeeInputChange(index, e)}
              disabled={isEmployeeUploading}
              className={`block w-full bg-[#1F1F1F] border ${formEmployeeErrors[`description-${index}`] ? 'border-red-500' : 'border-[#373737]'
                } rounded-md shadow-sm p-2 text-white ${isEmployeeUploading ? 'opacity-50 cursor-not-allowed' : ''}`}
            />
            {formEmployeeErrors[`description-${index}`] && (
              <p className="mt-1 text-sm text-red-500">{formEmployeeErrors[`description-${index}`]}</p>
            )}
          </div>
          <div className="mb-4">
            <label htmlFor={`amount-${index}`} className="block text-sm font-medium text-gray-300 mb-1">
              Amount
            </label>
            <input
              type="number"
              id={`amount-${index}`}
              name="amount"
              value={data.amount}
              onChange={(e) => handleEmployeeInputChange(index, e)}
              disabled={isEmployeeUploading}
              className={`block w-full bg-[#1F1F1F] border ${formEmployeeErrors[`amount-${index}`] ? 'border-red-500' : 'border-[#373737]'
                } rounded-md shadow-sm p-2 text-white ${isEmployeeUploading ? 'opacity-50 cursor-not-allowed' : ''}`}
            />
            {formEmployeeErrors[`amount-${index}`] && (
              <p className="mt-1 text-sm text-red-500">{formEmployeeErrors[`amount-${index}`]}</p>
            )}
          </div>
          <div>
            <label
              htmlFor="date"
              className="block text-sm font-medium text-gray-300 mb-1"
            >
              Date
            </label>
            <input
              type="date"
              id="date"
              name="date"
              value={formDataEmployee.date}
              onChange={(e) => handleEmployeeInputChange(index, e)}
              className={`block w-full bg-[#1F1F1F] border ${formEmployeeErrors.date ? "border-red-500" : "border-[#373737]"
                } rounded-md shadow-sm p-2 text-white`}
              disabled={isEmployeeUploading}
              max={new Date().toISOString().split('T')[0]} // Set max to the current date
            />
            {formEmployeeErrors[`date-${index}`] && (
              <p className="mt-1 text-sm text-red-500">{formEmployeeErrors[`date-${index}`]}</p>
            )}
          </div>
        </div>
      ))}
      <div className="flex flex-col space-y-4 mb-3 mt-6">
        <button
          type="button"
          className={`text-gray-900 bg-gray-100 hover:bg-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-100 font-medium rounded-lg text-sm px-2.5 py-1.5 text-center inline-flex items-center justify-center ${isEmployeeUploading ? 'opacity-50 cursor-not-allowed' : ''
            }`}
          onClick={employeeAddSection}
          disabled={isEmployeeUploading}
        >
          <Plus className="w-5 h-5 mr-2" />
          Add Bill
        </button>
      </div>
      <button
        onClick={onEmployeeReimbursementSubmit}
        className={`w-full px-4 py-2 rounded-md transition-colors text-black ${isEmployeeUploading
          ? 'bg-gray-400 cursor-not-allowed'
          : 'bg-blue-600 hover:bg-blue-700'
          }`}
        disabled={isEmployeeUploading}
      >
        {!isEmployeeUploading ? "Submit" : "uploading..."}
      </button>

      {timelineItems.length > 0 && (
        <div className="mt-8" aria-live="polite">
          <h4 className="text-lg font-medium mb-4 text-gray-200">Progress</h4>
          <Timeline>
            {timelineItems.map((item, index) => (
              <TimelineItem key={index} title={item.title} status={item.status} />
            ))}
          </Timeline>
        </div>
      )}
    </>
  )

  const renderBankInfo = () => (
    <>
      <h3 className="text-xl font-medium mb-4 text-gray-200 flex flex-col gap-6">
        <div className="flex w-full justify-between items-center">
          <p className="text-sm font-regular text-gray-100 opacity-65">
            Step 3 of 3
          </p>
          {/* <button
            className="text-[16px] font-regular text-gray-100 opacity-65 flex gap-1 items-center"
            onClick={() => setCurrentStep(2)}
          >
            <ArrowLeft size={18} />
            Back
          </button> */}
        </div>
        <div className="flex items-center gap-6">
          Your Bank Information
        </div>
      </h3>
      {!isBankInfoExist &&
        <div className="bg-yellow-100 border-l-4 border-yellow-500 text-yellow-700 p-4 mb-6" role="alert">
          <p className="font-bold">Bank Information Not Found</p>
          <p>Please contact the central team to add your bank information. You cannot add it directly.</p>
        </div>
      }
      <div className="space-y-4 mb-6">
        <div>
          <label
            htmlFor="employee_code"
            className="block text-sm font-medium text-gray-300 mb-1"
          >
            Bank Name
          </label>
          <input
            type="text"
            id="bank_name"
            name="bank_name"
            value={bankInfo?.bank_name}
            onChange={handleBankInfo}
            className={`block w-full bg-[#1F1F1F]
              } rounded-md shadow-sm p-2 text-white`}
            disabled
          />
        </div>
        <div>
          <label
            htmlFor="employee_code"
            className="block text-sm font-medium text-gray-300 mb-1"
          >
            Account Number
          </label>
          <input
            type="number"
            id="account_number"
            name="account_number"
            value={bankInfo.account_number}
            onChange={handleBankInfo}
            className={`block w-full bg-[#1F1F1F]
              } rounded-md shadow-sm p-2 text-white`}
            disabled
          />
        </div>
        <div>
          <label
            htmlFor="hub"
            className="block text-sm font-medium text-gray-300 mb-1"
          >
            Account Holder's Name
          </label>
          <input
            type="text"
            id="account_holder_name"
            name="account_holder_name"
            value={bankInfo.account_holder_name}
            onChange={handleBankInfo}
            disabled
            className={`block w-full bg-[#1F1F1F]
              } rounded-md shadow-sm p-2 text-white`}
          />
        </div>
        <div>
          <label
            htmlFor="hub"
            className="block text-sm font-medium text-gray-300 mb-1"
          >
            IFSC Code
          </label>
          <input
            type="text"
            id="ifsc_code"
            name="ifsc_code"
            value={bankInfo.ifsc_code}
            disabled
            onChange={handleBankInfo}
            className={`block w-full bg-[#1F1F1F]
              } rounded-md shadow-sm p-2 text-white`}
          />
        </div>
      </div>
      <button
        onClick={() => onBankInfoSubmit()}
        className={`w-full px-4 py-2 rounded-md transition-colors bg-white text-black`}
      >
        Continue
      </button>
    </>
  )

  const renderEmployeeBankInfo = () => (
    <>
      <h3 className="text-xl font-medium mb-4 text-gray-200 flex flex-col gap-6">
        <div className="flex w-full justify-between items-center">
          <p className="text-sm font-regular text-gray-100 opacity-65">
            Step 3 of 3
          </p>
          {/* <button
            className="text-[16px] font-regular text-gray-100 opacity-65 flex gap-1 items-center"
            onClick={() => setCurrentEmployeeStep(2)}
          >
            <ArrowLeft size={18} />
            Back
          </button> */}
        </div>
        <div className="flex items-center gap-6">
          Your Bank Information
        </div>
      </h3>
      {!isBankInfoExist &&
        <div className="bg-yellow-100 border-l-4 border-yellow-500 text-yellow-700 p-4 mb-6" role="alert">
          <p className="font-bold">Bank Information Not Found</p>
          <p>Please contact the central team to add your bank information. You cannot add it directly.</p>
        </div>
      }
      <div className="space-y-4 mb-6">
        <div>
          <label
            htmlFor="employee_code"
            className="block text-sm font-medium text-gray-300 mb-1"
          >
            Bank Name
          </label>
          <input
            type="text"
            id="bank_name"
            name="bank_name"
            value={bankInfo?.bank_name}
            onChange={handleBankInfo}
            className={`block w-full bg-[#1F1F1F]
              } rounded-md shadow-sm p-2 text-white`}
            disabled
          />
        </div>
        <div>
          <label
            htmlFor="employee_code"
            className="block text-sm font-medium text-gray-300 mb-1"
          >
            Account Number
          </label>
          <input
            type="number"
            id="account_number"
            name="account_number"
            value={bankInfo.account_number}
            onChange={handleBankInfo}
            className={`block w-full bg-[#1F1F1F]
              } rounded-md shadow-sm p-2 text-white`}
            disabled
          />
        </div>
        <div>
          <label
            htmlFor="hub"
            className="block text-sm font-medium text-gray-300 mb-1"
          >
            Account Holder's Name
          </label>
          <input
            type="text"
            id="account_holder_name"
            name="account_holder_name"
            value={bankInfo.account_holder_name}
            onChange={handleBankInfo}
            disabled
            className={`block w-full bg-[#1F1F1F]
              } rounded-md shadow-sm p-2 text-white`}
          />
        </div>
        <div>
          <label
            htmlFor="hub"
            className="block text-sm font-medium text-gray-300 mb-1"
          >
            IFSC Code
          </label>
          <input
            type="text"
            id="ifsc_code"
            name="ifsc_code"
            value={bankInfo.ifsc_code}
            disabled
            onChange={handleBankInfo}
            className={`block w-full bg-[#1F1F1F]
              } rounded-md shadow-sm p-2 text-white`}
          />
        </div>
      </div>
      <button
        onClick={() => onEmployeeBankInfoSubmit()}
        className={`w-full px-4 py-2 rounded-md transition-colors bg-white text-black`}
      >
        Continue
      </button>
    </>
  )

  const renderonSuccess = () => (
    <div className="w-full h-screen flex flex-col gap-28 pt-10 animate-slide-in-right">
      <div className="flex flex-col items-center gap-6">
        <CircleCheckBig className="w-16 h-16 text-white animate-bounce" />
        <p className="text-2xl ">Completed</p>
      </div>
      <p className="text-white text-center">You have successfully submitted your Information</p>
    </div>
  )

  const renderEmployeeSuccess = () => (
    <div className="w-full h-screen flex flex-col gap-28 pt-10 animate-slide-in-right">
      <div className="flex flex-col items-center gap-6">
        <CircleCheckBig className="w-16 h-16 text-white animate-bounce" />
        <p className="text-2xl ">Completed</p>
      </div>
      <p className="text-white text-center">You have successfully submitted your Information</p>
    </div>
  )

  const renderHubsReimbursementSteps = () => (
    <>
      {currentStep === 1 && renderReviewInformation()}
      {currentStep === 2 && renderImageUpload()}
      {currentStep === 3 && renderBankInfo()}
      {currentStep === 4 && renderonSuccess()}
    </>
  );

  const renderEmployeeReimbursementSteps = () => (
    <>
      {currentEmployeeStep === 1 && renderEmployeeReviewInformation()}
      {currentEmployeeStep === 2 && renderEmployeeExpenseBill()}
      {currentEmployeeStep === 3 && renderEmployeeBankInfo()}
      {currentEmployeeStep === 4 && renderEmployeeSuccess()}
    </>
  );

  return (
    <div className="max-w-md mx-auto p-6 bg-[#191919] rounded-lg shadow-xl text-gray-100">
      <h2 className="flex items-center gap-2 text-[16px] font-medium mb-6 text-white-400 justify-center">
        <span>DeliveryPlus: </span>
        <span className="bg-gray-100 text-gray-800 text-sm font-medium me-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-gray-300">{` ${reimbursementType} reimbursement`}</span>
      </h2>

      {reimbursementType === 'hubs' ? renderHubsReimbursementSteps() : renderEmployeeReimbursementSteps()}

    </div>
  );
};

export default Expense;